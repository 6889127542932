@if (control) {
  @if (!disabled) {
    <div class="text-container">
      <input [formControl]="control" [placeholder]="placeholder" type="text" (focus)="switchFocus()" (focusout)="switchFocus()" (beforeinput)="onBeforeInput($event)" />
      @if (focused && counter) {
        <div class="charcounter-container">
          <span class="charcounter" [class.exceeded]="maxlength && (control.value?.length ?? 0) > maxlength">
            <span>{{ control.value?.length || "0" }}</span>
            @if (maxlength !== null) {
              <span>/{{ maxlength }}</span>
            }
          </span>
        </div>
      }
    </div>
  } @else {
    <app-template-html [control]="control" [value]="value"></app-template-html>
  }
}
