import { Component, Input, OnInit } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { DefaultComponent } from "src/app/default.component";
import { PrefixTemplate } from "../../PrefixTemplate";
import { PrefixValidator } from "../../PrefixValidator";
import { MaxLength } from "../../types/MaxLength";
import { MinLength } from "../../types/MinLength";
import { Regex } from "../../types/Regex";

@Component({
  standalone: true,
  imports: [ReactiveFormsModule, MatIconModule],
  selector: "app-template-password",
  templateUrl: "./template-password.component.html",
  styleUrl: "./template-password.component.less",
})
export class TemplatePasswordComponent extends DefaultComponent implements PrefixTemplate<string, string>, OnInit {
  @Input({ required: true })
  public control: FormControl<string | null> | null;

  @Input()
  public value: string | null;

  @Input()
  public minlength: MinLength;

  @Input()
  public maxlength: MaxLength;

  @Input()
  public regex: Regex;

  @Input()
  public required: boolean;

  @Input()
  public disabled: boolean;

  public visible: boolean;

  @Input()
  public characters: string | null;

  @Input()
  public placeholder: string;

  public constructor() {
    super();
    this.control = null;
    this.value = null;
    this.minlength = null;
    this.maxlength = null;
    this.regex = null;

    this.required = false;
    this.disabled = false;

    this.visible = false;
    this.characters = null;

    this.placeholder = "";
  }

  public ngOnInit(): void {
    const control = this.control;
    if (control) {
      this.addValidators(control);
    } else {
      throw new Error("Undefined control");
    }
  }

  /**
   * Toggle the input type
   */
  public toggleVisibility(): void {
    this.visible = !this.visible;
  }

  public onBeforeInput(event: InputEvent): void {
    const input = event.data?.split("") ?? [];
    const invalid = !!input.filter((str) => this.characters && !this.characters.includes(str)).length;
    if (invalid) event.preventDefault();
  }

  private addValidators(control: FormControl<string | null>): void {
    if (this.minlength) control.addValidators([PrefixValidator.minlength(this.minlength)]);
    if (this.maxlength) control.addValidators([PrefixValidator.maxlength(this.maxlength)]);
    if (this.regex) control.addValidators([PrefixValidator.regex(this.regex)]);
    if (this.required) control.addValidators([PrefixValidator.required()]);

    control.updateValueAndValidity();
  }
}
