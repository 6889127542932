import { BehaviorSubject } from "rxjs";
import { STORAGE } from "../config/storage.config";
import { LOCALES } from "../enums/locales";
import { SessionPermission } from "../enums/session-permission";
import { SessionRole } from "../interfaces/session/session-role";
import { SessionWorkspace } from "../interfaces/session/session-workspace";

export class Session {
  public id: string;
  public userId: number;
  public name: string;
  public locale: LOCALES;
  public permissions: SessionPermission[];

  public role: BehaviorSubject<SessionRole | null>;
  public workspace: BehaviorSubject<SessionWorkspace | null>;
  public status: BehaviorSubject<string | null>;

  public translationid: string | null;

  public authenticated: BehaviorSubject<boolean | null>;

  public constructor(id: string) {
    this.id = id;
    this.name = "Guest";
    this.userId = -1;
    this.locale = LOCALES[localStorage.getItem(STORAGE.LOCALE) || "NL"];
    this.permissions = [];
    this.role = new BehaviorSubject<SessionRole | null>(null);
    this.workspace = new BehaviorSubject<SessionWorkspace | null>(null);
    this.authenticated = new BehaviorSubject<boolean | null>(null);
    this.status = new BehaviorSubject<string | null>(null);

    this.translationid = null;
  }

  /**
   * check if session has permission
   * @param id
   * @returns
   */
  public hasPermission(id: string): boolean {
    return this.permissions.includes(SessionPermission[id]);
  }

  /**
   * Remove a permission
   * @param id
   */
  public removePermission(id: string): void {
    const permission = SessionPermission[id];

    if (permission) {
      this.permissions = this.permissions.filter((_permission) => _permission != permission);
    } else {
      throw new Error(`Invalid permission: ${permission}`);
    }
  }

  /**
   * Add a permission
   * @param id
   */
  public addPermission(id: string): void {
    const permission = SessionPermission[id];

    if (permission) {
      this.permissions.push(permission);
    } else {
      throw new Error(`Invalid permission: ${permission}`);
    }
  }
}
