import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Location } from "@angular/common";
import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subject, distinctUntilChanged, firstValueFrom, map, take } from "rxjs";
import { DialogService, DialogTemplate } from "src/app/components/global/dialog/dialog.service";
import {
  LimitedPrintDialogComponent,
  LimitedPrintDialogData,
} from "src/app/components/global/dialog/impl/limited-print-dialog/limited-print-dialog.component";
import { SelectBookmarkComponent, SelectBookmarkDialogData } from "src/app/components/global/dialog/impl/select-bookmark/select-bookmark.component";
import { ErrorMessageComponent } from "src/app/components/global/snackbar/impl/error-message/error-message.component";
import { SuccessMessageComponent } from "src/app/components/global/snackbar/impl/success-message/success-message.component";
import { WarningMessageComponent } from "src/app/components/global/snackbar/impl/warning-message/warning-message.component";
import { SnackbarService } from "src/app/components/global/snackbar/snackbar.service";
import { Layout } from "src/enums/layout";
import { CONFIG } from "src/environments/environment";
import { CONFIG_ORGANISATION } from "src/environments/environment.organisation";
import { HistoryHandler } from "../classes/HistoryHandler";
import { STORAGE } from "../config/storage.config";
import { LOCALES } from "../enums/locales";
import { Message } from "../interfaces/post-request/post-request";
@Injectable({
  providedIn: "root",
})
export class ApplicationService {
  public router: Router;
  public location: Location;
  public snackbar: SnackbarService;
  private translate: TranslateService;
  private dialog: DialogService;

  public name: string;
  public theme: BehaviorSubject<string>;
  public debug: boolean;
  public index: string | null;

  public loading: BehaviorSubject<boolean>;
  public title: BehaviorSubject<string | null>;
  public locale: BehaviorSubject<LOCALES>;
  public context: BehaviorSubject<{ id: string; value: string }[]>;
  public splitscreen: BehaviorSubject<string | null>;
  public history: HistoryHandler;
  public breakpoint: BreakpointObserver;
  public layout: Layout;
  public layoutChanged: Subject<void>;
  public limitedPrint: BehaviorSubject<boolean>;

  public translationsLoaded: BehaviorSubject<boolean>;

  public deactivate: boolean;

  public constructor() {
    this.router = inject(Router);
    this.location = inject(Location);
    this.snackbar = inject(SnackbarService);
    this.translate = inject(TranslateService);
    this.dialog = inject(DialogService);

    this.name = CONFIG_ORGANISATION.TITLE;
    this.theme = new BehaviorSubject("light");
    this.debug = CONFIG.debug;
    this.index = sessionStorage.getItem(STORAGE.TABINDEX) || null;

    this.loading = new BehaviorSubject(true);
    this.title = new BehaviorSubject<string | null>(null);
    this.context = new BehaviorSubject<{ id: string; value: string }[]>([]);
    this.splitscreen = new BehaviorSubject<string | null>(null);
    this.breakpoint = inject(BreakpointObserver);
    this.layout = Layout.DESKTOP;
    this.layoutChanged = new Subject();
    this.limitedPrint = new BehaviorSubject(false);

    this.deactivate = true;
    this.locale = new BehaviorSubject<LOCALES>(<LOCALES>localStorage.getItem(STORAGE.LOCALE) || LOCALES.NL);

    this.history = new HistoryHandler(this);

    this.translationsLoaded = new BehaviorSubject(false);

    this.locale.pipe(distinctUntilChanged()).subscribe((locale) => {
      localStorage.setItem(STORAGE.LOCALE, locale);
    });

    this.listenBreakpoint();
  }

  /**
   * Refresh the content page
   */
  public refresh(url = this.router.url, extra = {}): void {
    this.router.navigateByUrl("/app/loader", { skipLocationChange: true }).then(() => {
      this.router.navigate([url], { state: extra });
    });
  }

  /**
   * Start printing interface
   */
  public print(): void {
    document.body.classList.remove("limitedprint");
    if (this.limitedPrint.value) {
      const ref = this.dialog.open<LimitedPrintDialogData>(
        LimitedPrintDialogComponent,
        { title: { label: "DIALOG.LIMITEDPRINT.TITLE" } },
        DialogTemplate.MODAL,
        {},
      );
      ref
        .afterClosed()
        .pipe(take(1))
        .subscribe((value) => {
          if (typeof value === "boolean") {
            if (value) {
              document.body.classList.add("limitedprint");
            }
            window.print();
          }
          document.body.classList.remove("limitedprint");
        });
    } else {
      window.print();
    }
  }

  /**
   * Duplicate current content interface (copy)
   */
  public async duplicate(): Promise<void> {
    const urls = this.router.url.split("/");
    if (urls.includes("content")) {
      this.splitscreen.next(urls.reverse()[0]);
    }
  }

  /**
   * Pin current content page for bookmarks
   */
  public pin(): void {
    this.dialog.open<SelectBookmarkDialogData>(
      SelectBookmarkComponent,
      {
        title: { label: "DIALOG.BOOKMARKS.CREATE.TITLE" },
      },
      DialogTemplate.MODAL,
    );
  }

  /**
   * Set the id of the current tab instance
   */
  public setTabIndex(id: string): void {
    this.index = id;
    sessionStorage.setItem("tabindex", id);
    console.warn("[SESSION] Setting access token => ", {
      tabindex: id,
    });
  }

  /**
   * Handle all incoming messages
   * @param messages
   */
  public onMessage(messages: Message[]): void {
    let refresh = false;
    let block = false;
    let previous = false;
    let redirect: string | null = null;

    for (const message of messages) {
      if (message.Refresh) refresh = true;
      if (message.Back) previous = true;
      if (message.GoToLink) redirect = message.LinkToGoTo;
      if (message.Block) block = true;

      switch (message.Type) {
        case 1:
        case 4:
          this.snackbar.open<string>(SuccessMessageComponent, message.Message);
          break;

        case 2:
          this.snackbar.open<string>(WarningMessageComponent, message.Message);
          break;

        case 3:
          this.snackbar.open<string>(ErrorMessageComponent, message.Message);
          break;

        default:
          throw new Error(`Unhandeled message type => ${message.Type}`);
      }
    }

    if (block) {
      //TODO - make content blockable
    } else {
      if (redirect) {
        this.router.navigate([`app/content/${redirect}`]);
      } else if (refresh) {
        this.refresh();
      } else if (previous) {
        this.history.previous();
      }
    }
  }

  public async updateLanguage(translation: Record<string, string>): Promise<void> {
    const lang = this.translate.currentLang;
    const current = await firstValueFrom(this.translate.getTranslation(lang));
    this.translate.setTranslation(lang, { ...current, ...translation });
    this.translate.use(lang);
    this.translationsLoaded.next(true);
  }

  /**
   * set screen size
   */
  public listenBreakpoint(): void {
    this.breakpoint
      .observe(Object.values(Breakpoints))
      .pipe(
        map((state) => {
          const breakpoints = Object.entries(Breakpoints);
          return <Record<keyof typeof Breakpoints, boolean>>Object.fromEntries(
            Object.entries(state.breakpoints).map(([query, matches]) => {
              const result = breakpoints.find(([, _query]) => _query === query);
              if (result) {
                return [result[0], matches];
              } else {
                return [query, matches];
              }
            }),
          );
        }),
        map((devices) => {
          switch (true) {
            case devices.XLarge:
              return Layout.DESKTOP;
            case devices.Large:
            case devices.Web:
            case devices.WebLandscape:
              return Layout.LAPTOP;

            case devices.Tablet:
            case devices.TabletLandscape:
            case devices.TabletPortrait:
            case devices.Medium:
            case devices.WebPortrait:
              return Layout.TABLET;

            case devices.HandsetLandscape:
              return Layout.MOBILE_LANDSCAPE;

            default:
              return Layout.MOBILE;
          }
        }),
      )
      .subscribe((result) => {
        this.layout = result;
        this.layoutChanged.next();
      });
  }
}
