import { bootstrapApplication } from "@angular/platform-browser";
import { MsalRedirectComponent } from "@azure/msal-angular";
import { AppComponent } from "./app/app.component";
import { appConfig } from "./app/app.config";
import { CONFIG_ORGANISATION } from "./environments/environment.organisation";

bootstrapApplication(AppComponent, appConfig)
  .then((appRef) => {
    appRef.bootstrap(MsalRedirectComponent);
  })
  .catch((err) => console.error(err));

const script = document.createElement("script");
script.setAttribute("src", `https://maps.googleapis.com/maps/api/js?key=${CONFIG_ORGANISATION.MAP.KEY}&v=weekly&libraries=maps,marker`);
document.body.append(script);
