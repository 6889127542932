import { Component } from "@angular/core";
import { DefaultComponent } from "../default.component";

@Component({
  standalone: true,
  selector: "app-splash",
  imports: [],
  templateUrl: "./splash.component.html",
  styleUrl: "./splash.component.less",
})
export class SplashScreenComponent extends DefaultComponent {
  public constructor() {
    super();
  }
}
