import { AfterViewInit, Component, inject } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ApplicationService } from "src/services/application.service";
import { DefaultComponent } from "../default.component";

@Component({
  standalone: true,
  selector: "app-logged-out",
  imports: [TranslateModule, RouterModule],
  templateUrl: "./logged-out.component.html",
  styleUrl: "./logged-out.component.less",
})
export class LoggedOutComponent extends DefaultComponent implements AfterViewInit {
  public application: ApplicationService;

  public constructor() {
    super();
    this.application = inject(ApplicationService);
  }
  public ngAfterViewInit(): void {
    this.application.loading.next(false);
  }
}
