<div id="login-wrapper">
  <div id="login-container">
    <div class="header">
      <div class="language">
        <button type="button" (click)="setLocale('NL')">
          <img src="../../../../images/languages/nl.svg" />
        </button>
        <button type="button" (click)="setLocale('EN')">
          <img src="../../../../images/languages/gb.svg" />
        </button>
      </div>

      <div class="logo">
        <img src="../../../assets/organisation/images/logo-auth.svg" />
      </div>

      @if (env.QR_LOGIN) {
        @switch (application.layout) {
          @case ("mobile") {}
          @case ("mobile-landscape") {}
          @default {
            <div class="qr">
              <button type="button" (click)="toggleQR()">
                <mat-icon [tooltip]="'LOGIN.QR_SWITCH'">{{ showQR.value ? "arrow_back_ios" : "qr_code_scanner" }}</mat-icon>
              </button>
            </div>
          }
        }
      }
    </div>

    <div class="inner">
      <form (submit)="login()">
        <div class="field field-prefix" #usernameRef>
          <label>{{ "LOGIN.USERNAME" | translate }}</label>
          <app-prefix-oms #username [counter]="false" [placeholder]="'LOGIN.USERNAME' | translate" />
        </div>

        <div class="field field-prefix" #passwordRef>
          <label>{{ "LOGIN.PASSWORD" | translate }}</label>
          <app-prefix-wac #password [placeholder]="'LOGIN.PASSWORD' | translate" />
        </div>

        <button type="submit" id="login-button" [disabled]="isLoading">
          {{ "LOGIN.SUBMIT" | translate }}
        </button>
      </form>
      <div class="link">
        <a [routerLink]="['/recover']">{{ "LOGIN.RECOVER" | translate }}</a>
      </div>

      @if (showQR.value) {
        <div id="code-container">
          @if (qrcode) {
            <span>{{ "LOGIN.QR_TITLE" | translate }}</span>

            <app-prefix-qrc [value]="qrcode" />
            <div class="timer" [ariaLabel]="'LOGIN.QR_ARIALABEL' | translate">
              <app-timer [ms]="qrIntervalTime" [order]="'DESC'" (onfinish)="generateQR()" type="bar" />
            </div>
          }
        </div>
      }
      @if (isLoading) {
        <div id="loading-container">
          <mat-progress-spinner [color]="'primary'" [diameter]="48" [mode]="'indeterminate'" />
        </div>
      }
    </div>
  </div>
  @if (showNews && news) {
    <div id="news-container">
      <div class="inner" [innerHTML]="news"></div>
    </div>
  }
</div>
