import { HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { ApplicationService } from "../services/application.service";

// interface Request {
//   body: unknown;
//   context: HttpContext;
//   headers: HttpHeaders;
//   method: string;
//   params: HttpParams;
//   reportProgress: boolean;
//   responseType: string;
//   url: string;
//   urlWithParams: string;
//   withCredentials: boolean;
// }

export function beforeSendInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  switch (req.method) {
    case "GET":
      return getInterceptor(req, next);

    case "POST":
      return postInterceptor(req, next);

    default:
      return next(req.clone());
  }
}

function getInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const application = inject(ApplicationService);
  const requestTime = new Date().getTime().toString();

  return next(
    req.clone({
      setParams: {
        _: requestTime,
        tabindex: application.index || "",
      },
      withCredentials: true,
    }),
  );
}

function postInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const application = inject(ApplicationService);
  const requestTime = new Date().getTime().toString();

  let body = req.body;
  const time = requestTime;
  const index = application.index;

  if (req.body instanceof FormData) {
    (<FormData>body).set("tabindex", index || "");
  } else {
    body = {
      ...(<object>req.body),
      _: time,
      tabindex: index || "",
    };
  }

  return next(
    req.clone({
      body: body,
      withCredentials: true,
    }),
  );
}
