<div id="recover-wrapper" [style.background]="'url(../../../assets/organisation/images/background.png)'">
  <div id="recover-container">
    <div class="header">
      <div class="logo">
        <img src="../../../assets/organisation/images/logo-auth.svg" />
      </div>
    </div>
    <div class="inner">
      <form>
        <div class="field field-prefix">
          <label>{{ "RECOVER.USERNAME" | translate }}</label>
          <app-prefix-oms [counter]="false" (controlchanged)="username = $event" />
        </div>

        <div class="field field-prefix">
          <app-prefix-jan [options]="[{ label: 'RECOVER.CHECK' }]" (controlchanged)="check = $event" />
        </div>

        <button type="button" (click)="recover()">
          {{ "RECOVER.SUBMIT" | translate }}
        </button>

        <div class="link">
          <a [routerLink]="['/login']">{{ "RECOVER.LOGIN" | translate }}</a>
        </div>

        @if (isLoading) {
          <div id="loading-container">
            <mat-progress-spinner [color]="'primary'" [diameter]="48" [mode]="'indeterminate'" />
          </div>
        }
      </form>
    </div>
  </div>
</div>
