import { Environment } from "src/interfaces/environment";

export const CONFIG_ORGANISATION: Environment = {
  organisation: "fun",
  TITLE: "FarmerUnite",
  uri_api: "https://dev.api.farmerunite.com",
  websocketUrl: "https://dev.api.farmerunite.com/socket",
  SHOW_HISTORY: true,
  QR_LOGIN: false,
  QR_REFRESH_RATE: 60000,
  QR_CHECK_RATE: 5000,
  msal: null,
  SUBFUNCTIONALITIES: [],
  MAP: {
    KEY: "",
    ID: "",
  },
};
