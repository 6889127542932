import { ComponentType } from "@angular/cdk/overlay";
import { ApplicationRef, ComponentRef, Directive, ElementRef, EnvironmentInjector, Input, OnDestroy, createComponent, inject } from "@angular/core";
import { TextTooltipComponent } from "src/app/components/global/tooltip/impl/text-tooltip/text-tooltip.component";
import { TooltipComponent } from "src/app/components/global/tooltip/tooltip.component";
import { CONFIG } from "src/environments/environment";
import { OVERLAYS } from "../config/overlays.config";

export type PopupPosition = "start" | "end" | "bottom" | "top";

export interface PopupOptions {
  followCursor: boolean;
  position: PopupPosition;
  showDuration: number;
  hideDuration: number;
  arrow: boolean;
}

export interface PopupConfig<DATA = unknown> {
  component: ComponentType<TooltipComponent>;
  data: DATA | null;
  handlers: { leave: () => void; enter: () => void } | null;
  options?: Partial<PopupOptions>;
}

interface Config extends PopupConfig {
  options: PopupOptions;
}

@Directive({})
export abstract class PopupDirective implements OnDestroy {
  private host: ElementRef<HTMLElement>;
  private injector: EnvironmentInjector;
  private appRef: ApplicationRef;

  public config: Config;
  public overlay: HTMLElement | null;

  @Input()
  public options: PopupOptions | null;

  public visible: boolean;

  public timer: NodeJS.Timeout | null;

  protected reference: ComponentRef<TooltipComponent> | null;

  public constructor() {
    this.host = inject(ElementRef);
    this.injector = inject(EnvironmentInjector);
    this.appRef = inject(ApplicationRef);

    this.overlay = null;
    this.options = null;
    this.timer = null;
    this.reference = null;

    this.visible = false;

    this.config = {
      component: TextTooltipComponent,
      data: null,
      handlers: null,
      options: {
        followCursor: false,
        position: "bottom",
        hideDuration: 50,
        showDuration: 50,
        arrow: true,
      },
    };
  }

  public ngOnDestroy(): void {
    this.toggle(false);
  }

  protected initialize(data: string | Partial<PopupConfig> | null): void {
    if (data && !this.reference) {
      if (typeof data == "string") {
        this.renderText(data);
      } else {
        this.renderComponent(data);
      }
    }

    if (!this.overlay) {
      this.overlay = this.getOverlay();
    }
  }

  /**
   * Toggle show/hide tooltip
   */
  protected toggle(visible = !this.visible): void {
    const ref = this.reference;
    const overlay = this.overlay;

    if (ref && overlay) {
      const options = this.config.options;
      if (visible) {
        const element = <HTMLElement>ref.location.nativeElement;
        if (this.config.options.arrow) {
          visible ? element.classList.add("tooltip-arrow") : element.classList.remove("tooltip-arrow");
          element.classList.remove("end", "start", "top", "bottom", "hor-start", "hor-end", "hor-center", "ver-start", "ver-end", "ver-center");
        }

        overlay.appendChild(element);
        this.appRef.attachView(ref.hostView);

        setTimeout(() => {
          const host = this.host.nativeElement.getBoundingClientRect();
          const tooltip = element.getBoundingClientRect();

          const horizontal = {
            start: host.left - tooltip.width,
            center: host.width / 2 + host.left - tooltip.width / 2,
            end: host.right,
          };

          const vertical = {
            top: host.top - tooltip.height,
            bottom: host.top + host.height,
            center: host.top + host.height / 2 - tooltip.height / 2,
          };

          let order: PopupPosition[] = [];
          switch (options.position) {
            case "start":
              order = ["start", "end", "bottom", "top"];
              break;
            case "end":
              order = ["end", "start", "bottom", "top"];
              break;
            case "bottom":
              order = ["bottom", "top", "end", "start"];
              break;
            case "top":
              order = ["top", "bottom", "end", "start"];
              break;
          }

          const position = this.checkPositions(element, order);

          if (position) {
            switch (position) {
              case "top":
                this.setPosition(element, horizontal.center, vertical.top);
                break;

              case "bottom":
                this.setPosition(element, horizontal.center, vertical.bottom);
                break;

              case "start":
                this.setPosition(element, horizontal.start, vertical.center);
                break;

              case "end":
                this.setPosition(element, horizontal.end, vertical.center);
                break;
            }
            if (this.config.options.arrow) element.classList.add(position);
            element.style.visibility = "visible";
          } else {
            if (CONFIG.debug) console.warn("Unable to place tooltip. No available space!");
          }
        }, 0);
      } else {
        const child = overlay.querySelector(`#tooltip-${ref.instance.uuid}`);
        if (child) {
          overlay.removeChild(child);
          this.appRef.detachView(ref.hostView);
        }
      }
    }

    this.visible = visible;
  }

  /**
   * Set position of element
   * @param element
   */
  protected setPosition(element: HTMLElement, x: number, y: number, positions = { top: true, right: true }): void {
    const host = this.host.nativeElement.getBoundingClientRect();
    const overlay = this.getOverlay().getBoundingClientRect();
    const tooltip = element.getBoundingClientRect();
    let verPosition = "center";
    let horPosition = "center";

    let top = 0;
    let left = 0;

    if (positions.top) {
      top = y;
    } else {
      top = element.clientHeight - y;
    }

    if (positions.right) {
      left = x;
    } else {
      left = x - element.clientWidth - 24;
    }

    if (left + tooltip.width > overlay.width) {
      left = host.right - tooltip.width; // if overflows on right side, tooltip end = host end
      horPosition = "end";
    }
    if (left < 0) {
      left = host.left; // if overflows on left side, tooltip start = host start
      horPosition = "start";
    }

    if (top + tooltip.height > overlay.height) {
      top = host.bottom - tooltip.height; // if overflows on bottom, tooltip end = host end
      verPosition = "end";
    }
    if (top < 0) {
      top = host.top; // if overflows on top, tooltip top = host top
      verPosition = "start";
    }

    if (this.config.options.arrow) {
      const position = this.config.options.position;
      if (["start", "end"].includes(position)) element.classList.add(`ver-${verPosition}`);
      if (["top", "bottom"].includes(position)) element.classList.add(`hor-${horPosition}`);

      if (horPosition === "end") {
        left += 8;
      }
    }

    element.style.top = `${top}px`;
    element.style.left = `${left}px`;
  }

  /**
   * Render tooltip with component
   * @param tooltip
   */
  private renderComponent({ component, data, options }: Partial<PopupConfig>): void {
    const config = this.config;
    config.component = component || config.component;
    config.data = data || config.data;
    config.options = {
      ...this.config.options,
      ...options,
    };
    this.reference = this.createComponent(config);
  }

  /**
   * Render tooltip with text
   * @param tooltip
   */
  private renderText(tooltip: string): void {
    const config = this.config;
    config.data = tooltip;
    this.reference = this.createComponent(config);
  }

  /**
   * Get or create the tooltip overlay
   * @returns
   */
  private getOverlay(): HTMLElement {
    let overlay = document.getElementById(OVERLAYS.TOOLTIP);

    if (!overlay) {
      const body = document.querySelector("body");
      if (body) {
        overlay = document.createElement("div");
        overlay.setAttribute("id", OVERLAYS.TOOLTIP);
        body.appendChild(overlay);
      } else {
        throw new Error("Undefined body");
      }
    }

    return overlay;
  }

  /**
   * Set the tooltip reference element
   * @param config
   */
  private createComponent(config: PopupConfig): ComponentRef<TooltipComponent> {
    const ref = createComponent(config.component, {
      environmentInjector: this.injector,
    });
    const element = ref.location.nativeElement;
    ref.instance.data = config.data;
    element.setAttribute("id", `tooltip-${ref.instance.uuid}`);
    if (this.config.handlers) {
      element.addEventListener("mouseleave", () => this.config.handlers?.leave());
      element.addEventListener("mouseenter", () => this.config.handlers?.enter());
    }
    return ref;
  }

  private checkPositions(tooltip: HTMLElement, positionOrder: PopupPosition[]): PopupPosition | null {
    for (const position of positionOrder) {
      if (this.checkPosition(tooltip, position)) return position;
    }
    return null;
  }

  private checkPosition(tooltip: HTMLElement, position: PopupPosition): boolean {
    const host = this.host.nativeElement.getBoundingClientRect();
    const bounding = tooltip.getBoundingClientRect();
    const overlay = this.getOverlay().getBoundingClientRect();
    switch (position) {
      case "start":
        return host.left - bounding.width >= 0;
      case "end":
        return host.right + bounding.width <= overlay.width;
      case "bottom":
        return host.bottom + bounding.height <= overlay.height;
      case "top":
        return host.top - bounding.height >= 0;
      default:
        return false;
    }
  }
}
