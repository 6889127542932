import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ErrorMessageComponent } from "src/app/components/global/snackbar/impl/error-message/error-message.component";
import { SnackbarService } from "src/app/components/global/snackbar/snackbar.service";

export interface HttpObserve<T = unknown> {
  headers: HttpHeaders;
  body: T;
}

@Injectable({
  providedIn: "root",
})
export class HttpService {
  private http: HttpClient;
  private snackbar: SnackbarService;

  public constructor() {
    this.http = inject(HttpClient);
    this.snackbar = inject(SnackbarService);
  }

  /**
   * Retrieve data from url
   * @param url
   * @param data
   * @returns
   */
  public async retrieve<T = unknown>(url: string, data: unknown): Promise<T> {
    try {
      return await firstValueFrom(this.http.get<T>(`${url}?${new URLSearchParams(<Record<string, string>>data).toString()}`));
    } catch (error) {
      this.onError(error);
      throw error;
    }
  }

  /**
   * Send data to url
   * @param url
   * @param data
   * @returns
   */
  public async send<T = unknown>(url: string, data: unknown, options: Parameters<typeof this.http.post>[2] = {}): Promise<T> {
    try {
      return await firstValueFrom(this.http.post<T>(url, data, options));
    } catch (error) {
      this.onError(error);
      throw error;
    }
  }

  /**
   * send formdata to url
   * @param url
   * @param data
   * @param headers
   * @returns
   */
  public async sendForm<T = unknown>(url: string, data: Record<string, unknown>, headers = {}): Promise<T> {
    const formdata = new FormData();
    for (const [key, value] of Object.entries(data)) formdata.set(key, <string | Blob>value);
    try {
      return await firstValueFrom(this.http.post<T>(url, formdata, headers));
    } catch (error) {
      this.onError(error);
      throw error;
    }
  }

  private onError(error: unknown): void {
    console.error("Failed to load result => ", error);
    this.snackbar.open<string>(ErrorMessageComponent, "ERRORS.RESPONSE");
  }
}
