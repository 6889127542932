import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { TranslateModule } from "@ngx-translate/core";
import { DefaultComponent } from "src/app/default.component";
import { IconReplacePipe } from "src/pipes/icon-replace.pipe";
import { PrefixTemplate } from "../../PrefixTemplate";
import { PrefixValidator } from "../../PrefixValidator";

export type Direction = "horizontal" | "vertical";

export interface CheckboxOption {
  label: string;
}

export interface CheckboxValue {
  [key: string]: boolean;
}

export type CheckboxState = null | false | true;

@Component({
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, CommonModule, MatCheckboxModule, IconReplacePipe],
  selector: "app-template-checkbox",
  templateUrl: "./template-checkbox.component.html",
  styleUrl: "./template-checkbox.component.less",
})
export class TemplateCheckboxComponent<CONTROL, VALUE = CONTROL> extends DefaultComponent implements PrefixTemplate<CONTROL, VALUE>, OnInit {
  @Input({ required: true })
  public control: FormControl<CONTROL> | null;

  @Input()
  public value: VALUE | null;

  @Input()
  public required: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  public options: CheckboxOption[];

  @Input()
  public direction: Direction;

  public group: FormGroup;

  public constructor() {
    super();
    this.control = null;
    this.value = null;
    this.required = false;
    this.disabled = false;

    this.options = [];
    this.direction = "vertical";
    this.group = new FormGroup({});
  }

  public ngOnInit(): void {
    const control = this.control;
    if (control) {
      this.addValidators(control);
      this.group = new FormGroup(this.createForm());
      this.addSubscription(
        this.group.valueChanges.subscribe((value) => {
          if (this.control) {
            this.control.markAsDirty();
            this.control.setValue(value);
          }
        }),
      );
    } else {
      throw new Error("Undefined control");
    }
  }

  private addValidators(control: FormControl<CONTROL>): void {
    if (this.required) control.addValidators([PrefixValidator.required()]);

    control.updateValueAndValidity();
  }

  /**
   * Create FormGroup based on options
   * @returns
   */
  private createForm(): Record<string, FormControl<boolean>> {
    return this.options.reduce((total, option) => {
      const key = option.label;
      const value = this.value || {};
      const record = Object.keys(value).find((initial) => initial == key);
      return {
        ...total,
        [key]: new FormControl(record ? value[record] || false : false),
      };
    }, {});
  }
}
